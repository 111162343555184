<template>
  <common-page-table
    :classHelp="'userPage'"
    custom-expand
    :onExpand="onExpand"
    :dataExpand="dataExpand"
    rowKey="userId"
    :apiName="apiName"
    :nameRole="nameRoleEntity"
    :titleTable="$t('Пользователи')"
    :paramsGetData="paramsGetData"
    :hasLang="false"
    :prefixEdit="`/user`"
    :prefixShow="`/user`"
    :linkCreate="`/user/create`"
    :keyTable="'userId'"
    :alternativeConverter="alternativeConverter"
    :isDefaultConverter="false"
    :deleteParams="deleteParams"
    :apiDeleteName="apiDeleteName"
    :wAction="250"
  />
</template>
<script>
import { NAME_OBJECT } from "../../../../RoleConstanans";
import CommonPageTable from "../../commonElement/CommonPageTable.vue";
import { API_METHODS, FIELD_LANG_CODE } from "../../../../constant";
import formatData from "../../../../helpers/converters/formatData";
import convertLangDataToTable from "../../../../helpers/converters/convertLangDataToTable";
import {ApiService} from "@/util/ApiService";
import userPage from "@/components/modules/user/UserPage.vue";
export default {
  name: "UserPage",
  components: { CommonPageTable },
  data() {
    return {
      loadDataCompany: {},


      fieldLang: "",
      apiName: API_METHODS.GET_ALL_USER,
      apiDeleteName: API_METHODS.DELETE_USER
    };
  },
  computed: {
    userPage() {
      return userPage
    },
    paramsGetData() {
      return { UserId: this.$route.params.UserIdId };
    },
    nameRoleEntity() {
      return NAME_OBJECT.Admin
    }
  },
  methods: {
    dataExpand(row) {
      if(this.loadDataCompany[row.userId]) {
        return this.loadDataCompany[row.userId];
      }
      return []
    },
    async onExpand(row) {
      const params = {
        self: this,
        userId: row.userId,
        saveData: {
        },
        query: {
          lang: this.$store.state.common.lang
        }
      };
      const res = await ApiService(API_METHODS.GET_UserCompany, params);
      this.$set(this.loadDataCompany,  row.userId, res.object);
    },

    deleteParams(id) {
      return { id:id };
    },
    alternativeConverter(dataNow, langNow) {
      if (dataNow && dataNow.length > 0) {
        let tempdataNow = convertLangDataToTable(dataNow, langNow, FIELD_LANG_CODE, true);
        tempdataNow = tempdataNow.map(item => {
          return {
           ...item, isActive: item.isActive?this.$t('Да'):this.$t("Нет"), validDate :  formatData(item.validDate)
          };
        });
        return tempdataNow;
      }

      return [];
    }
  }
};
</script>

<style >
.userPage {
  overflow: visible;
}
.userPage .el-table__body-wrapper {
  overflow: visible;
}


</style>
